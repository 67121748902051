<template>
	<div>
		<navComponents />
		<div class="bg1" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg1-box">
					<div class="bg1-text-box">
						<p class="p1">通过StaRiver RDP快速准备想要的数据，让数据自由流动，让数据随需即得、开箱即用！</p>
						<p class="p2" v-if="!isMobile">集实时、离线/定时、可视化ETL、调度、数据开发、低代码平台API发布为一体。</p>
						<button v-if="!isMobile" @click="$router.push('/request_trial')">申请免费体验</button>
					</div>
					<div class="bg1-video-box" v-if="!isMobile">
						<!-- <img style="" ref="img" src="@/assets/images/homegif2.gif" alt="" srcset="" />
						<video ref="video" src="http://istariver.com/video/product-introduction.mp4" controls></video> -->
					</div>
				</div>
			</div>
		</div>
		<div class="bg2" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg2-img"></div>
				<div class="bg2-title">
					<p>一个快速的关键任务数据工作流的地方</p>
					<div v-show="!isMobile"></div>
				</div>
				<div class="bg2-4box mobile">
					<div class="smallBox" @click="openDialog(index + 1)" v-for="(item, index) in bg2Data" :key="index">
						<div class="icon-box">
							<svg-icon :icon-class="isMobile ? item.icon + '-lan' : item.icon" style="width: 100%; height: 100%"></svg-icon>
						</div>
						<div class="icon-text">
							<div class="p1">{{ item.title }}</div>
							<div class="p2">{{ item.content }}</div>
							<div class="p3" v-if="!isMobile">
								<div class="icon-p3">
									<svg-icon icon-class="productIntroduction-bg2-jiantou" style="width: 100%; height: 100%"></svg-icon>
								</div>
								<div class="text-p3">查看详情</div>
							</div>
						</div>

						<div class="icon-p3" v-if="isMobile">
							<svg-icon icon-class="productIntroduction-bg2-jiantou-lan" style="width: 100%; height: 100%"></svg-icon>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg3" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg3-title">
					<div class="p1">「产品架构」</div>
					<div class="p2">
						通过多种实时数据技术，支持广泛的数据节点类型，协助客户构建以业务目标为导向的数据链路，按需快速定制、
						部署、执行数据任务，以支持从传统数据处理到实时数据应用的各类场景。
					</div>
					<div class="hengxian" v-show="!isMobile"></div>
				</div>
				<div class="bg3-img1">
					<!-- <img src="~@/assets/images/productIntroduction-bg3-1.png" alt="" /> -->
					<svg-icon icon-class="productIntroduction-bg3-1" style="width: 100%; height: 100%"></svg-icon>
				</div>
				<div class="bg3-title2">
					<div class="p1">「主要优势」</div>
					<div class="hengxian"></div>
				</div>
				<div class="bg3-5box" v-if="!isMobile">
					<div class="small-box1">
						<div class="small-box1-1">
							<p class="p1">数据新鲜度</p>
							<div class="p2">
								<div class="p2-icon"></div>
								<div class="p2-text">陈旧的批次数据可能会让您失去一位客户，而新鲜数据保证了对运营数据的最新见解，从而做出有利可图的实时决策。</div>
							</div>
						</div>
						<div class="small-box1-1">
							<p class="p1">灵活性和自由度</p>
							<div class="p2">
								<div class="p2-icon"></div>
								<div class="p2-text">根据需要轻松添加和删除新目标 - 使用“ReadOnceWriteMany”方法，您无需担心影响生产数据库。</div>
							</div>
						</div>
					</div>
					<div class="small-box2">
						<p class="p1">数百个数据管道每天可以 流式传输数十亿个事件</p>
						<div class="p2">
							<div class="p2-icon"></div>
							<div class="p2-text">
								无论是一年中最繁忙的旅行日还是黑色星期五，StaRiver RDP都可以为您一年中最繁忙的时间提供支持，并每天播放数十亿个活动。
							</div>
						</div>
						<div class="bg3-img3"></div>
					</div>
					<div class="small-box1">
						<div class="small-box1-1">
							<p class="p1">可扩展性和吞吐量</p>
							<div class="p2">
								<div class="p2-icon"></div>
								<div class="p2-text">随着业务的扩展无限扩展，无需任何额外的计划或执行成本，从而节省时间和金钱。</div>
							</div>
						</div>
						<div class="small-box1-1">
							<p class="p1">100 多个企业资源和目标</p>
							<div class="p2">
								<div class="p2-icon"></div>
								<div class="p2-text">使用单一解决方案随时随地连接任何数据，从而降低管理多个产品和工具的成本。</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bg3-5box" v-else>
					<div class="small-box1-1" v-for="(item, index) in bg3Data" :key="index">
						<div class="p2-icon"></div>
						<div class="p2">
							<p class="p1">{{ item.title }}</p>
							<div class="p2-text">{{ item.content }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg4" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg4-title">
					<div class="p1">「核心功能」</div>
					<div class="p2">低代码可视化配置操作</div>
					<div class="hengxian" v-if="!isMobile"></div>
				</div>
				<div class="bg4-lunbo" v-if="!isMobile">
					<!-- <div class="bg4-nav">
						<ul>
							<li
								v-for="(item, index) in bg4Data"
								:key="index"
								:class="[item.active == true ? 'isActive' : '']"
								@click="changeli(index)"
							>
								<div class="bg4-li-index">
									{{ item.liIndex }}
								</div>
								<div class="bg4-li-name">
									{{ item.name }}
								</div>
								<div class="bg4-li-icon">
									<svg-icon icon-class="productIntroduction-bg4-liicon" style="width: 100%; height: 100%"></svg-icon>
								</div>
							</li>
						</ul>
					</div> -->
					<div class="bg4-imgbox">
						<el-carousel indicator-position="outside" type="card" :interval="4000" height="340px" @change="changeImgIndex" ref="carousel">
							<el-carousel-item v-for="(item, index) in bg4Data" :key="index">
								<img :src="item.url" alt="" />
								<p>{{ item.liIndex }} {{ item.name }}</p>
								<div class="model"></div>
							</el-carousel-item>
						</el-carousel>
						<!-- <ul>
							<template v-for="item in bg4Data">
								<li id="lunbotu" :key="item.liindex"><img :src="item.url" alt="" />{{ item.liindex }}</li>
							</template>
						</ul>
						<ul>
							<template v-for="item in bg4Data2">
								<li id="lunbotu2" :key="item.liindex">
									<img :src="item.url" alt="" />
								</li>
							</template>
						</ul> -->
					</div>
				</div>
				<swiper v-if="isMobile" ref="mySwiper" v-show="isMobile" :options="swiperOptions">
					<swiper-slide v-for="(item, index) in bg4Data" :key="index">
						<img :src="item.url" alt="" />
						<div class="bottom">
							<div>{{ item.liIndex }} {{ item.name }}</div>
							<div class="back">{{ index + 1 }}/{{ bg4Data.length }}</div>
						</div>
					</swiper-slide>
					<!-- 如果你需要分页器 -->
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		<CopyrightComponents />

		<!-- 弹出框 -->
		<el-dialog :visible.sync="dialogVisible" width="1000px">
			<div slot="title" class="dialog-title">
				<div class="p1">{{ text1 }}</div>
				<div class="p2">{{ text2 }}</div>
				<div class="button-right">
					<span class="title-close" @click="dialogVisible = false"></span>
				</div>
			</div>
			<div v-if="openflag == 1">
				<div class="dialog1-bg1">
					<div class="p1">为什么CDC优于传统ETL？</div>
					<div class="p2-4box">
						<div class="smallp2">
							<div class="icon45deg"></div>
							<div class="p2">随着新数据库事务的发生，变更数据不断移动，使您能够立即响应时间敏感问题。</div>
						</div>
					</div>
					<div class="p2-4box">
						<div class="smallp2">
							<div class="icon45deg"></div>
							<div class="p2">CDC 最大限度地减少了源系统的开销，延长了硬件寿命，并规避了批处理窗口限制。</div>
						</div>
					</div>
					<div class="p2-4box">
						<div class="smallp2">
							<div class="icon45deg"></div>
							<div class="p2">只移动全天不断变化的数据可以节省带宽并节省通信成本。</div>
						</div>
					</div>
					<div class="p2-4box">
						<div class="smallp2">
							<div class="icon45deg"></div>
							<div class="p2">对目标数据库或数据仓库应用更改时，这些更改的顺序和事务性保持不变。</div>
						</div>
					</div>
				</div>
				<div class="dialog1-bg2">
					<div class="p1">在StaRiver RDP上更改数据功能</div>
					<div class="p2">使用现代 ETL 摆脱批处理操作的束缚，该 ETL 在数据更改时复制数据。</div>
					<div class="p3">
						在从主要企业数据库（包括 Oracle、SQLServer HPE NonStop、MySQL、PostgreSQL、MongoDB
						等）摄取时使用基于日志的更改数据捕获。它最大限度地减少了资源上的 CPU 开销，不需要更改应用程序，以及维护解决方案的大量管理开销。
					</div>
					<div class="dialog1-bg2-6box">
						<div class="smallbox">
							<div class="smallbox-p1">异构集成</div>
							<div class="smallbox-p2">
								StaRiver RDP的实时数据摄取不仅限于数据库和 CDC 方法。使用StaRiver RDP，您可以将来自 OLTP
								系统的实时事务数据与实时日志数据、消息系统、传感器数据、NoSQL 和 Hadoop 数据合并，以获得关于您的业务的丰富、全面和可靠的信息。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">维护事务上下文</div>
							<div class="smallbox-p2">
								从数据库日志中提取更改数据时，StaRiver
								RDP移动已提交的事务并维护事务上下文。在整个数据移动、处理和交付步骤中，都会保留此事务上下文，以便用户可以创建可靠的副本数据库。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">流式处理</div>
							<div class="smallbox-p2">
								StaRiver RDP提供开箱即用的转换器和内存流处理功能，可在运动时过滤、聚合、屏蔽、转换和丰富变化数据。使用基于 SQL 的连续查询，StaRiver
								RDP立即将更改数据转换为最终用户可使用的格式，而不会丢失事务上下文。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">关键任务可靠性</div>
							<div class="smallbox-p2">
								随着数据通过StaRiver RDP平台的内存组件移动和处理，解决方案记录和跟踪每个操作。如果出现中断，StaRiver
								RDP可以从中断的地方重播事务——不会丢失数据或重复。使用Striim集群的内置恢复和高可用性确保了关键任务的可靠性。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">内置数据验证</div>
							<div class="smallbox-p2">
								该平台在数据移动时不断比较源系统和目标系统，验证数据库是否一致并且所有更改的数据都已应用于目标。在必须避免数据丢失的用例中，例如迁移到新的云数据存储，此功能极大地降低了迁移风险。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">云和本地变更交付</div>
							<div class="smallbox-p2">
								使用相同的软件，StaRiver RDP不仅可以将更改数据实时传送到本地数据库，还可以传送到在云、云服务、消息传递系统、文件、Hadoop 和 NoSQL
								环境中运行的数据库。StaRiver RDP的集成数据流应用程序可以有多个具有并发实时数据交付的目标。
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="openflag == 2">
				<div class="dialog2-bg1">
					<div class="smallbox">
						<div class="p1">为什么选择智能数据管道？</div>
						<div class="p2">在正确的时间使用正确的数据做出更好、更快的决策。最适合您业务的解决方案是智能数据管道。</div>
					</div>
					<div class="smallbox">
						<div class="p1">即时访问</div>
						<div class="p2">通过连接本地和云环境来加速您的现代数据架构，并更轻松地访问业务用户。</div>
					</div>
					<div class="smallbox">
						<div class="p1">即时洞察</div>
						<div class="p2">
							智能数据管道可实时访问您的流数据。借助云数据仓库中始终可用的新数据，您可以产生可行的见解并更快地做出决策，以满足客户的期望。
						</div>
					</div>
				</div>
				<div class="dialog2-bg2">
					<div class="p1">StaRiver RDP智能数据管道的主要特点</div>
					<div class="dialog2-bg2-7box">
						<div class="smallbox">
							<div class="smallbox-p1">实时数据集成</div>
							<div class="smallbox-p2">
								实时集成意味着您可以在正确的时间访问正确的数据，从而为您的业务做出正确的决策。智能数据管道通过实时数据移动和内置连接器实现这一切，这些连接器可以将多个管道馈送到不同的数据目标。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">与位置无关</div>
							<div class="smallbox-p2">
								无论您的数据是驻留在本地还是在云端，智能数据管道都可以弥合旧服务器与您的组织蓬勃发展所需的最新应用程序之间的差距。智能数据管道是将现代数据架构结合在一起的粘合剂。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">基于流数据构建的应用程序</div>
							<div class="smallbox-p2">
								智能数据管道使您能够使用熟悉的 SQL
								轻松地在流数据上构建应用程序，以便您可以快速开始。一旦智能数据管道到位，您就可以利用机器学习和自动响应来保持竞争优势。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">可扩展性</div>
							<div class="smallbox-p2">
								根据数据需求无限扩展的能力可能导致数据成本迅速上升。智能数据管道不仅使您能够轻松扩展，而且还能够以最具成本效益的方式进行扩展。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">可靠性</div>
							<div class="smallbox-p2">
								智能数据管道可靠地交付您所有的关键工作流程并确保零停机时间。在 Smart Data Pipelines
								之前，如果不手动管理整个过程以确保没有重复消息，您永远无法保证完全一次或至少一次处理能够正常工作。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">模式演变</div>
							<div class="smallbox-p2">
								随着您的业务发展，您对各种应用程序的使用也会随之发展。随着连接的应用程序的发展，自动模式演变可帮助您跟上源数据库的变化。Smart Data
								Pipelines 配备了模式演化功能，因此用户可以准确指定他们希望如何处理 DDL 更改。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">管道监控</div>
							<div class="smallbox-p2">
								为您的数据客户提供内置仪表板和监控，以便他们可以轻松地实时监控其数据流的状态。当秒数对您的业务很重要时，Smart Data Pipelines
								可以保证数据的新鲜度。
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="openflag == 3">
				<div class="dialog3-bg1">
					<div class="smallbox">
						<div class="p1">为什么选择流式处理？</div>
						<div class="p2">在现代数据架构中使用流式 SQL 实时转换、过滤、聚合和丰富数据。</div>
					</div>
					<div class="smallbox">
						<div class="p1">流式处理之前</div>
						<div class="p2">如果没有流式处理，SQL 团队会浪费宝贵的时间甚至几天等待数据加载然后进行转换。</div>
					</div>
					<div class="smallbox">
						<div class="p1">流式处理之后</div>
						<div class="p2">借助 Streaming SQL，团队可以获得近乎即时的业务洞察力，并提供个性化的体验，让客户回头客。</div>
					</div>
				</div>
				<div class="dialog3-bg2">
					<div class="p1">StaRiver上的流式处理功能</div>
					<div class="dialog3-bg2-4box">
						<div class="smallbox">
							<div class="smallbox-p1">自动提醒让您领先一步</div>
							<div class="smallbox-p2">
								StaRiver RDP在 slack 和电子邮件中提供开箱即用的警报，可以针对各种指标进行配置，让您随时了解数据流的状态和性能。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">了解整个数据旅程</div>
							<div class="smallbox-p2">StaRiver RDP具有开箱即用的洞察力和监控功能，可一目了然地向您显示数据流的状态。</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">从您的数据流中获得更深入的 洞察力</div>
							<div class="smallbox-p2">通过深入研究数十个端到端指标（例如数据新鲜度、CPU 使用率和数据摄取率）来获得更高的可见性。</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">直观的自定义分析仪表板</div>
							<div class="smallbox-p2">
								StaRiver
								RDP可以轻松地为您的实时分析工作负载定制仪表板。使用表容量指标深入挖掘您的数据管道，对数据驱动的场景发出警报，甚至将机器学习整合到您的分析中，以更深入地了解您的实时数据。
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="openflag == 4">
				<div class="dialog4-bg1">
					<div class="smallbox">
						<div class="p1">使用新数据</div>
						<div class="p2">实时集成您的数据，无需修改或减慢源系统</div>
					</div>
					<div class="smallbox">
						<div class="p1">打破数据孤岛</div>
						<div class="p2">从各种系统中提取和交付，包括数据仓库、日志文件、异构数据库、传感器和消息队列</div>
					</div>
					<div class="smallbox">
						<div class="p1">构建现代数据架构</div>
						<div class="p2">使用端到端的企业级平台，通过流式分析通过参考数据预处理和丰富实时数据</div>
					</div>
				</div>
				<div class="dialog4-bg2">
					<div class="p1">实时数据集成变得容易</div>
					<div class="dialog3-bg2-4box">
						<div class="smallbox">
							<div class="smallbox-p1">快速入门</div>
							<div class="smallbox-p2">
								StaRiver
								RDP将实时变更数据捕获功能与动态数据处理和数据可视化相结合，为企业提供及时和分析就绪的数据。基于向导的用户界面提供了直观的开发体验，并通过预建的数据管道加快了部署时间。借助基于
								SQL 的引擎，StaRiver RDP易于业务分析师和开发人员使用。
							</div>
						</div>
						<div class="smallbox">
							<div class="smallbox-p1">为什么选择StaRiver RDP进行实时数据集成</div>
							<div class="smallbox-p2">
								借助StaRiver RDP，您可以通过集成来自数据库、消息传递系统、文件、数据仓库、数据湖和 IoT 的本地和云数据轻松采用下一代基础框架。
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
//页面引入vue-awesome-swiper 及其样式
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";

export default {
	components: {
		navComponents,
		CopyrightComponents,
		swiper,
		swiperSlide,
	},
	props: {},
	data() {
		return {
			isActiveIndex: 0,
			bg2Data: [
				{
					title: "变更数据捕获",
					content: "StaRiver RDP为实时 ETL 采用变更数据捕获 (CDC)，以比以往更快地在旧系统和云应用程序中移动数据。",
					icon: "productIntroduction-bg2-1",
				},
				{
					title: "智能数据管道",
					content: "借助 Smart Data Pipelines 从分析到行动，您可以轻松自定义和扩展以满足企业不断变化的需求。",
					icon: "productIntroduction-bg2-2",
				},
				{
					title: "流式处理和实时分析",
					content: "克服来自慢速批处理作业的陈旧数据，并使用流式处理向所有消费者提供实时的分析就绪数据。",
					icon: "productIntroduction-bg2-3",
				},
				{
					title: "实时数据集成",
					content: "构建现代实时数据架构，让您的数据以亚秒级延迟到达需要的位置。",
					icon: "productIntroduction-bg2-4",
				},
			],
			bg3Data: [
				{
					title: "数据新鲜度",
					content: "陈旧的批次数据可能会让您失去一位客户，而新鲜数据保证了对运营数据的最新见解，从而做出有利可图的实时决策。",
				},
				{
					title: "灵活性和自由度",
					content: "根据需要轻松添加和删除新目标 - 使用“ReadOnceWriteMany”方法，您无需担心影响生产数据库。",
				},
				{
					title: "数百个数据管道每天可以 流式传输数十亿个事件",
					content: "无论是一年中最繁忙的旅行日还是黑色星期五，StaRiver RDP都可以为您一年中最繁忙的时间提供支持，并每天播放数十亿个活动。",
				},
				{
					title: "可扩展性和吞吐量",
					content: "随着业务的扩展无限扩展，无需任何额外的计划或执行成本，从而节省时间和金钱。",
				},
				{
					title: "100 多个企业资源和目标",
					content: "使用单一解决方案随时随地连接任何数据，从而降低管理多个产品和工具的成本。",
				},
			],
			bg4Data: [
				{
					liIndex: "01",
					name: "任务监控大屏",
					active: true,
					liindex: 0,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo1.png"),
				},
				{
					liIndex: "02",
					name: "元数据管理",
					active: true,
					liindex: 1,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo2.png"),
				},
				{
					liIndex: "03",
					name: "数据同步",
					active: true,
					liindex: 2,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo3.png"),
				},
				{
					liIndex: "04",
					name: "文件同步",
					active: true,
					liindex: 3,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo4.png"),
				},
				{
					liIndex: "05",
					name: "任务流",
					active: true,
					liindex: 4,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo5.png"),
				},
				{
					liIndex: "06",
					name: "数据质量",
					active: true,
					liindex: 5,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo6.png"),
				},
				{
					liIndex: "07",
					name: "数据发布",
					active: false,
					liindex: 6,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo7.png"),
				},
				{
					liIndex: "08",
					name: "系统设置",
					active: false,
					liindex: 7,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo8.png"),
				},
			],
			bg4Data2: [
				{
					liIndex: "08",
					name: "系统设置",
					active: false,
					liindex: 7,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo8.png"),
				},
				{
					liIndex: "01",
					name: "任务监控大屏",
					active: true,
					liindex: 0,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo1.png"),
				},
				{
					liIndex: "02",
					name: "元数据管理",
					active: true,
					liindex: 1,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo2.png"),
				},
				{
					liIndex: "03",
					name: "数据同步",
					active: true,
					liindex: 2,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo3.png"),
				},
				{
					liIndex: "04",
					name: "文件同步",
					active: true,
					liindex: 3,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo4.png"),
				},
				{
					liIndex: "05",
					name: "任务流",
					active: true,
					liindex: 4,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo5.png"),
				},
				{
					liIndex: "06",
					name: "数据质量",
					active: true,
					liindex: 5,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo6.png"),
				},
				{
					liIndex: "07",
					name: "数据发布",
					active: false,
					liindex: 6,
					url: require("@/assets/images/carousel/productIntroduction-bg4-lunbo7.png"),
				},
			],
			swiperOptions: {
				// 这里配置Swiper的参数
				// 例如：自动播放、分页等
				// autoplay: {
				// 	delay: 2500,
				// 	disableOnInteraction: false,
				// },
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				// 其他配置...
				centeredSlides: true,
				slidesPerView: 1,
			},
			dialogVisible: false,
			openflag: 1,
			text1: "",
			text2: "",
			screenWidth: "",
			screenHeight: "",

			mobileSrc: `url(${require("@/assets/images/homegif2-mobile.gif")})`,
			pcSrc: `url(${require("@/assets/images/homegif2.gif")})`,
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
		// let doms2 = document.querySelectorAll("#lunbotu2");
		// let doms = document.querySelectorAll("#lunbotu");
		// let i = 0;
		// let j = 7;
		// console.log(doms);
		// setInterval(() => {
		// 	i = i > 7 ? 0 : i;
		// 	j = j < 0 ? 7 : j;
		// 	// this.bg4Data[0].active = false;
		// 	// doms[0].style.width = "0px";
		// 	console.log(i, this.bg4Data);
		// 	// this.bg4Data[5].active = true;
		// 	this.bg4Data.push(this.bg4Data.shift());
		// 	doms.forEach(item => {
		// 		item.style.transform = "translateX(" + -433 * i + "px)";
		// 	});
		// 	// doms2[0].style.height = "0px";
		// 	// doms2[6].style.height = "230px";
		// 	// doms2[7].style.height = "0px";
		// 	// doms2[1].style.height = "230px";
		// 	// doms2[2].style.height = "230px";
		// 	// doms2[3].style.height = "230px";
		// 	// doms2[4].style.height = "230px";
		// 	// doms2[5].style.height = "230px";
		// 	i++;
		// 	j--;
		// }, 3000);
	},
	methods: {
		playVideo() {
			// console.log(this.$refs.img);
			// this.$refs.img.style.display = "none";
			// this.$refs.video.style.display = "block";
			// this.$refs.video.play();
			// this.$refs.video.addEventListener("ended", () => {
			// 	this.$refs.img.style.display = "block";
			// 	this.$refs.video.style.display = "none";
			// });
		},
		openDialog(val) {
			if (this.isMobile) {
				this.$router.push("/details?flag=" + val);
			} else {
				if (val == 1) {
					this.openflag = 1;
					this.text1 = "变更数据捕获的力量";
					this.text2 = "使用现代 ETL 摆脱批处理操作的束缚，该 ETL 在数据更改时复制数据。";
				} else if (val == 2) {
					this.openflag = 2;
					this.text1 = "智能数据管道";
					this.text2 =
						"从 A 点批量发送到 B 点的缓慢数据迁移和复制的日子已经一去不复返了。您可能已经尝试过数据管道，但是通过智能数据管道，您可以确保数据的不间断流动，以获得持续的洞察力和最佳的客户体验.";
				} else if (val == 3) {
					this.openflag = 3;
					this.text1 = "流式处理和实时分析";
					this.text2 = "使用流式处理克服批处理操作和传统 SQL 查询的限制，在StaRiver RDP上运行流式数据的连续查询。";
				} else {
					this.openflag = 4;
					this.text1 = "实时数据集成";
					this.text2 =
						"StaRiver RDP通过提供可靠、安全且可扩展的流式传输基础架构，以亚秒级延迟将您的数据传送到需要的位置，从而为现代实时数据架构奠定了基础。  ";
				}
				this.dialogVisible = true;
			}
		},
		changeli(val) {
			this.$refs.carousel.setActiveItem(val);
		},
		changeImgIndex(val) {
			console.log(val);
			this.bg4Data.forEach(item => {
				if (item.liindex == val) {
					item.active = true;
				} else {
					item.active = false;
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.bg1,
.bg2,
.bg3,
.bg4 {
	// width: 1903.5px;
}

.bg1 {
	height: 28vw;
	background-image: url("~@/assets/images/homegif2.gif");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	background-color: #0094ff;
	color: #fff;
	margin: 0 auto;

	.w {
		width: var(--container-width);
		margin: 0 auto;
		border: 1px solid transparent;
		height: 100%;

		.bg1-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.bg1-text-box {
				width: 650px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 50%;

				.p1 {
					font-size: 24px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #ffffff;
				}

				.p2 {
					font-size: 16.5px;
					font-family: "Microsoft YaHei";
					font-weight: 400;
					color: #ffffff;
					line-height: 32px;
				}

				button {
					width: 140px;
					height: 41px;
					background: #54cb9a;
					border-radius: 23px;
					text-align: center;
					line-height: 41px;
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #ffffff;
					border: 0;
					cursor: pointer;
					transition: 0.5s all;
					&:hover {
						transform: scale(1.1);
					}
					a {
						text-decoration: none;
						color: #ffffff;
						font-size: 14px;
					}
					&.disabled-btn {
						background: #cfcfcf;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						line-height: 25.6px;
						cursor: not-allowed;
						a {
							color: #000000;
							cursor: not-allowed;
						}
						&:hover {
							transform: scale(1);
						}
					}
				}
			}

			.bg1-video-box {
				// width: 546px;
				// height: 310px;
				width: 610px;

				// background: url("~@/assets/images/homegif2.gif") no-repeat center;
				background-size: 100% 100%;
				background-color: transparent;
				img,
				video {
					width: 546px;
					height: 310px;
					border-radius: 15px;
				}
				video {
					display: none;
				}
			}
		}
	}
}

.bg2 {
	height: 890px;
	margin: 0 auto;
	background-color: #f7f8fb;

	.w {
		width: 1300px;
		margin: 0 auto;
		border: 1px solid transparent;

		.bg2-img {
			background-color: pink;
			width: 887px;
			margin: 50px auto 0;
			height: 341px;
			background: url("~@/assets/images/productIntroduction-bg2.png") no-repeat center;
			background-size: 100% 100%;
		}

		.bg2-title {
			width: 400px;
			margin: 20px auto 0;

			p {
				font-size: 22px;
				font-family: "Microsoft YaHei";
				font-weight: 700;
				color: #1b1b1b;
			}

			div {
				margin: 30px auto 0;

				width: 60px;
				height: 2px;
				background: #1b1b1b;
			}
		}

		.bg2-4box {
			display: flex;
			justify-content: space-between;
			margin-top: 40px;

			.smallBox {
				width: 297px;
				height: 290px;
				background: linear-gradient(0deg, #0857d9, #3b85ff);
				cursor: pointer;
				display: flex;
				padding-left: 30px;
				padding-top: 42px;
				padding-right: 25px;
				transition: 0.5s all;

				&:hover {
					transform: scale(1.03);
				}

				.icon-box {
					width: 45px;
					height: 43px;
					margin-right: 20px;
				}

				.icon-text {
					flex: 1;

					.p1 {
						font-size: 16px;
						font-family: "Microsoft YaHei";
						font-weight: 700;
						color: #ffffff;
						margin-bottom: 40px;
					}

					.p2 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						font-weight: 500;
						color: #ffffff;
						height: 120px;
						line-height: 25.6px;
					}

					.p3 {
						margin-top: 25px;
						display: flex;

						.icon-p3 {
							width: 13px;
							height: 15px;

							svg {
								vertical-align: top;
							}
						}

						.text-p3 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							font-weight: 500;
							color: #ffffff;
							line-height: 15px;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
}

.bg3 {
	height: 1218px;
	background: url("~@/assets/images/productIntroduction-bg3-2.png") no-repeat center;
	background-size: 100% 100%;
	margin: 0 auto;

	background-color: #ffffff;

	.w {
		width: var(--container-width);
		margin: 0 auto;
		overflow: hidden;

		.bg3-title {
			width: 100%;
			margin: 70px auto 30px;

			.p1 {
				font-size: 22px;
				font-family: "Microsoft YaHei";
				font-weight: bold;
				color: #1b1b1b;
				text-align: center;
				margin-bottom: 13px;
			}

			.p2 {
				font-size: 14px;
				font-family: "Microsoft YaHei";
				font-weight: 400;
				color: #000000;
				text-align: center;
				line-height: 25.6px;
			}

			.hengxian {
				height: 2px;
				width: 60px;
				background-color: #1b1b1b;
				margin: 30px auto 40px;
			}
		}

		.bg3-img1 {
			margin-left: 96px;
			width: 1222px;
			height: 382px;
			// background: url("~@/assets/images/productIntroduction-bg3-1.png") no-repeat center;
			// background-size: 100% 100%;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.bg3-title2 {
			width: 35%;
			margin: 70px 0 0 435px;

			.p1 {
				font-size: 22px;
				font-family: "Microsoft YaHei";
				font-weight: bold;
				color: #1b1b1b;
				text-align: center;
				margin-bottom: 30px;
			}

			.hengxian {
				height: 2px;
				width: 60px;
				background-color: #1b1b1b;
				margin: 0 auto;
			}
		}

		.bg3-5box {
			padding-top: 5px;
			padding-left: 5px;
			padding-right: 5px;
			padding-bottom: 7px;
			margin-top: 40px;
			display: flex;
			justify-content: space-between;

			.small-box1 {
				height: 400px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.small-box1-1 {
					width: 440px;
					height: 190px;
					background: #ffffff;
					box-shadow: 0px 2px 9px 1px rgba(4, 0, 0, 0.1);
					padding-top: 41px;
					padding-left: 50px;
					padding-right: 51px;

					.p1 {
						font-size: 18px;
						font-family: "Microsoft YaHei";
						font-weight: 700;
						color: #1b1b1b;
					}

					.p2 {
						font-size: 14px;
						display: flex;
						justify-content: space-between;
						margin-top: 20px;

						.p2-icon {
							width: 12px;
							height: 12px;
							background-color: #696969;
							margin-right: 15px;
							transform: rotate(45deg);
							margin-top: 8px;
						}

						.p2-text {
							flex: 1;
							height: 90px;
							font-family: "Microsoft YaHei";
							font-weight: 500;
							color: #1b1b1b;
							line-height: 25.6px;
						}
					}
				}
			}

			.small-box2 {
				width: 369px;
				height: 400px;
				background: #ffffff;
				box-shadow: 0px 2px 9px 1px rgba(4, 0, 0, 0.1);
				padding-top: 41px;
				padding-left: 50px;
				padding-right: 51px;

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #1b1b1b;
					line-height: 25.6px;
				}

				.p2 {
					display: flex;
					justify-content: space-between;
					margin-top: 26px;

					.p2-icon {
						width: 12px;
						height: 12px;
						background-color: #696969;
						margin-right: 15px;
						transform: rotate(45deg);
						margin-top: 8px;
					}

					.p2-text {
						flex: 1;
						height: 90px;
						font-size: 14px;
						font-family: "Microsoft YaHei";
						font-weight: 500;
						color: #1b1b1b;
						line-height: 26px;
					}
				}

				.bg3-img3 {
					width: 128px;
					height: 103px;
					margin: 46px auto 0;
					background: url("~@/assets/images/productIntroduction-bg3-3.png") no-repeat center;
					background-size: 100%;
				}
			}
		}
	}
}

.bg4 {
	height: 630px;
	background-color: #f7f8fb;
	margin: 0 auto;

	.w {
		width: var(--container-width);
		margin: 0 auto;
		border: 1px solid transparent;

		.bg4-title {
			width: 30%;
			margin: 70px auto 0;

			.p1 {
				font-size: 20px;
				font-family: "Microsoft YaHei";
				font-weight: bold;
				color: #1b1b1b;
				text-align: center;
				margin-bottom: 13px;
			}

			.p2 {
				font-size: 14px;
				font-family: "Microsoft YaHei";
				font-weight: 400;
				color: #000000;
				text-align: center;
				margin-bottom: 30px;
			}

			.hengxian {
				height: 2px;
				width: 60px;
				background-color: #1b1b1b;
				margin: 0 auto;
			}
		}

		.bg4-lunbo {
			margin-top: 40px;
			height: 640px;
			display: flex;
			justify-content: space-between;

			.bg4-nav {
				width: 343px;
				height: 495px;

				ul {
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					height: 100%;

					li {
						width: 280px;
						height: 60px;
						background: url("~@/assets/images/productIntroduction-bg4-noActive.png") no-repeat center;
						background-size: 100% 100%;
						display: flex;
						justify-content: space-between;
						padding-left: 15px;
						padding-right: 20px;
						padding-top: 20px;
						cursor: pointer;
						transition: all 0.5s;
						&:hover {
							transform: scale(1.1);
						}

						.bg4-li-index {
							width: 19px;
							height: 18px;
							margin-right: 40px;
							font-size: 18px;
							font-family: "ShiShangZhongHei";
							color: #000000;
							line-height: 18px;
						}

						.bg4-li-name {
							height: 18px;
							line-height: 18px;
							font-size: 16px;
							font-family: "ShiShangZhongHei";
							flex: 1;
						}

						.bg4-li-icon {
							width: 13px;
							height: 15px;
							margin-left: 20px;
							margin-top: 3px;

							svg {
								vertical-align: top;
							}
						}
					}

					.isActive {
						background: url("~@/assets/images/productIntroduction-bg4-isActive.png") no-repeat center;

						.bg4-li-index {
							color: #fff;
						}

						.bg4-li-name {
							color: #fff;
						}

						.bg4-li-icon {
							svg {
								color: #fff;
							}
						}
					}
				}
			}

			.bg4-imgbox {
				// width: 957px;
				// height: 640px;
				overflow: hidden;
				width: 1300px;

				.el-carousel__item {
					font-size: 18px;
					margin: 0;
					img {
						width: 100%;
						height: 100%;
					}
					p {
						color: #fff;
						background-color: rgba(0, 0, 0, 0.4);
						position: absolute;
						bottom: 4px;
						width: 100%;
						text-align: center;
						font-size: 14px;
						line-height: 32px;
						transition: all 0.2s;
					}
					.model {
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background-color: rgba(0, 0, 0, 0.1);
						transition: all 0.2s;
					}
					&.is-active {
						.model {
							background-color: transparent;
						}
						p {
							background-color: rgba(20, 102, 238, 0.8);
						}
					}
					&:hover {
						.model {
							background-color: transparent;
						}
						p {
							background-color: rgba(20, 102, 238, 0.8);
						}
					}
				}

				ul {
					width: 3468px;
					height: 230px;
					display: flex;
					flex-wrap: wrap;
					overflow: hidden;
					&:last-child {
						margin-top: 40px;
					}
					li {
						width: 400px;
						transform: translate(-433px);
						height: 230px;
						list-style: none;
						margin: 0 auto;
						transition: all 0.5s;
					}
				}

				// .el-carousel__item:nth-child(2n) {
				//   background-color: #99a9bf;
				// }

				// .el-carousel__item:nth-child(2n+1) {
				//   background-color: #d3dce6;
				// }
			}
		}
	}
}

/deep/.el-dialog__wrapper {
	.el-dialog {
		margin-top: 0vh;
		.el-dialog__header {
			box-sizing: border-box !important;
			background-color: red;
			background: url("~@/assets/images/dialog.png") no-repeat center;
			height: 100px;
			padding-top: 15px;
		}
		.el-dialog__headerbtn {
			top: 15px;
		}
		.dialog-title {
			position: relative;

			.p1 {
				font-size: 20px;
				font-family: "Microsoft YaHei";
				font-weight: bold;
				color: #ffffff;
			}

			.p2 {
				font-size: 14px;
				margin-top: 8px;
				font-family: "Microsoft YaHei";
				font-weight: 400;
				color: #ffffff;
				width: 80%;
			}

			.button-right {
				position: absolute;
				right: -6px;
				top: -7px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #fff;
			}
		}

		.el-dialog__body {
			box-sizing: border-box;
			padding: 0;

			.dialog1-bg1 {
				padding: 30px;
				height: 231px;
				background-color: #fff;

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: 400;
					color: #0092fb;
					margin-bottom: 18px;
				}

				.p2-4box {
					.smallp2 {
						display: flex;

						.icon45deg {
							width: 12px;
							height: 12px;
							background-color: #747474;
							transform: rotate(45deg);
							margin-left: 3px;
							margin-right: 15px;
						}

						.p2 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							line-height: 14px;
							margin-bottom: 20px;
						}
					}
				}
			}

			.dialog1-bg2 {
				padding: 30px;
				background-color: #f7f8fb;

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #0092fb;
					margin-bottom: 22px;
				}

				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					margin-bottom: 13px;
				}

				.p3 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					line-height: 24px;
				}

				.dialog1-bg2-6box {
					margin-top: 25px;
					display: flex;
					justify-content: space-around;
					flex-wrap: wrap;

					.smallbox {
						width: 290px;
						height: 270px;
						background: #ffffff;
						border: 1px solid #dcdcdc;
						border-radius: 5px;
						margin-bottom: 25px;
						padding-top: 33px;
						padding-left: 23px;
						padding-right: 23px;

						.smallbox-p1 {
							font-size: 18px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #000000;
							margin-bottom: 15px;
						}

						.smallbox-p2 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							color: #000000;
							line-height: 25px;
						}
					}
				}
			}

			.dialog2-bg1 {
				padding: 30px;
				height: 297px;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				background-color: #fff;

				.smallbox {
					.p1 {
						font-size: 18px;
						font-family: "Microsoft YaHei";
						font-weight: 400;
						color: #0092fb;
						margin-bottom: 18px;
					}

					.p2 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						line-height: 14px;
					}
				}
			}

			.dialog2-bg2 {
				padding: 30px;
				padding-right: 0px;
				background-color: #f7f8fb;

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #0092fb;
					margin-bottom: 22px;
				}

				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					margin-bottom: 13px;
				}

				.p3 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					line-height: 24px;
				}

				.dialog2-bg2-7box {
					margin-top: 25px;
					display: flex;
					flex-wrap: wrap;

					.smallbox {
						width: 290px;
						height: 270px;
						background: #ffffff;
						border: 1px solid #dcdcdc;
						border-radius: 5px;
						margin-bottom: 25px;
						padding-top: 33px;
						padding-left: 23px;
						padding-right: 23px;
						margin-right: 33px;

						.smallbox-p1 {
							font-size: 18px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #000000;
							margin-bottom: 15px;
						}

						.smallbox-p2 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							color: #000000;
							line-height: 25px;
						}
					}
				}
			}

			.dialog3-bg1 {
				padding: 30px;
				height: 297px;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				background-color: #fff;

				.smallbox {
					.p1 {
						font-size: 18px;
						font-family: "Microsoft YaHei";
						font-weight: 400;
						color: #0092fb;
						margin-bottom: 18px;
					}

					.p2 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						line-height: 14px;
					}
				}
			}

			.dialog3-bg2 {
				padding: 30px;
				padding-right: 0px;
				background-color: #f7f8fb;

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #0092fb;
					margin-bottom: 22px;
				}

				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					margin-bottom: 13px;
				}

				.p3 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					line-height: 24px;
				}

				.dialog3-bg2-4box {
					margin-top: 25px;
					display: flex;
					flex-wrap: wrap;

					.smallbox {
						width: 290px;
						height: 270px;
						background: #ffffff;
						border: 1px solid #dcdcdc;
						border-radius: 5px;
						margin-bottom: 25px;
						padding-top: 33px;
						padding-left: 23px;
						padding-right: 23px;
						margin-right: 33px;

						.smallbox-p1 {
							font-size: 18px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #000000;
							margin-bottom: 15px;
						}

						.smallbox-p2 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							color: #000000;
							line-height: 25px;
						}
					}
				}
			}

			.dialog4-bg1 {
				padding: 30px;
				height: 297px;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				background-color: #fff;

				.smallbox {
					.p1 {
						font-size: 20px;
						font-family: "Microsoft YaHei";
						font-weight: 400;
						color: #0092fb;
						margin-bottom: 18px;
					}

					.p2 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						line-height: 14px;
					}
				}
			}

			.dialog4-bg2 {
				padding: 30px;
				padding-right: 0px;
				background-color: #f7f8fb;

				.p1 {
					font-size: 22px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #0092fb;
					margin-bottom: 22px;
				}

				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					margin-bottom: 13px;
				}

				.p3 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					color: #1b1b1b;
					line-height: 24px;
				}

				.dialog3-bg2-4box {
					margin-top: 25px;
					display: flex;
					flex-wrap: wrap;

					.smallbox {
						width: 290px;
						height: 270px;
						background: #ffffff;
						border: 1px solid #dcdcdc;
						border-radius: 5px;
						margin-bottom: 25px;
						padding-top: 33px;
						padding-left: 23px;
						padding-right: 23px;
						margin-right: 33px;

						.smallbox-p1 {
							font-size: 18px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #000000;
							margin-bottom: 15px;
						}

						.smallbox-p2 {
							font-size: 14px;
							font-family: "Microsoft YaHei";
							color: #000000;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.bg1 {
		height: 110vw;
		background-image: url("~@/assets/images/homegif2-mobile.gif");
		.w {
			height: 100%;
			.bg1-box {
				align-items: flex-end;
				height: 100%;
				margin-top: 0;
				.bg1-text-box {
					width: 100%;
					height: auto;
					padding: 20px 0;
					.p1 {
						margin: 0;
						font-size: 16px;
						color: rgba(255, 255, 255, 0.8);
						text-align: center;
						font-style: normal;
						line-height: 22px;
					}
				}
			}
		}
	}
	.bg2 {
		height: auto;
		.w {
			width: 100%;
			.bg2-img {
				margin: 35px auto 5px;
				width: 96vw;
				height: 38vw;
			}
			.bg2-title {
				width: 100%;
				margin: 0 auto;
				p {
					font-size: 18px;
					text-align: center;
				}
			}
			.mobile.bg2-4box {
				flex-direction: column;
				width: 92%;
				margin: 20px auto 0;

				.smallBox {
					background: #fff;
					box-shadow: 0px 2px 4px 2px rgba(98, 114, 146, 0.1);
					border-radius: 5px 5px 5px 5px;
					padding: 15px;
					width: 100%;
					height: auto;
					margin-bottom: 10px;
					.icon-box {
						width: 30px;
						height: 30px;
					}
					.icon-text {
						.p1,
						.p2,
						.p3 {
							color: #000000;
						}
						.p1 {
							margin-bottom: 10px;
						}
						.p2 {
							height: auto;
						}
					}
					.icon-p3 {
						margin-left: 20px;
						align-self: center;
						width: 13px;
						height: 15px;

						svg {
							vertical-align: top;
						}
					}
				}
			}
		}
	}
	.bg3 {
		height: auto;
		.w {
			.bg3-title {
				margin: 20px auto;
				.p1 {
					font-size: 20px;
				}
			}
			.bg3-img1 {
				width: 100%;
				margin-left: 0px;
				height: 125px;
			}
			.bg3-title2 {
				margin: 40px auto 0;
				width: 100%;
				.p1 {
					margin-bottom: 15px;
					font-size: 20px;
				}
			}
			.bg3-5box {
				flex-direction: column;
				margin-top: 15px;

				.small-box1-1 {
					width: 100%;
					height: 140px;
					background: #f5f5f5;
					border-radius: 5px 5px 5px 5px;
					padding: 15px;
					margin-bottom: 10px;
					display: flex;
					justify-content: space-between;

					.p1 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						font-weight: 700;
						color: #1b1b1b;
					}
					.p2-icon {
						width: 8px;
						height: 8px;
						background-color: #696969;
						margin-right: 12px;
						transform: rotate(45deg);
						margin-top: 8px;
					}

					.p2 {
						font-size: 12px;
						margin-top: 3px;
						flex: 1;

						.p2-text {
							font-family: "Microsoft YaHei";
							font-weight: 500;
							color: #595e67;
							line-height: 25.6px;
							text-align: justify;
							font-size: 14px;
							margin-top: 10px;
						}
					}
				}

				.small-box2 {
					width: 100%;
				}
			}
		}
	}
	.bg4 {
		height: auto;
		.w {
			.bg4-title {
				width: 100%;
				margin: 20px auto 0;
				.p2 {
					margin-bottom: 15px;
				}
			}
			.bg4-lunbo .bg4-imgbox {
				width: 100%;
			}
			.swiper-container {
				padding-bottom: 50px;
				.swiper-slide {
					img {
						width: 100%;
						height: 100%;
					}
				}
				.bottom {
					bottom: 0;
					width: 100%;
					position: absolute;
					// color: #fff;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 4px 10px;
					color: #fff;
					background-color: rgba(0, 0, 0, 0.5);
					div:first-child {
						font-size: 16px;
					}
					.back {
						background-color: rgba(0, 0, 0, 0.5);
						border-radius: 20px 20px 20px 20px;
						padding: 2px 10px;
					}
					// .model {
					// 	position: absolute;
					// 	height: 100%;
					// 	width: 100%;
					// 	top: 0;
					// 	left: 0;
					// 	background-color: rgba(0, 0, 0, 1);
					// 	transition: all 0.2s;
					// }
				}
				.swiper-pagination-bullets {
					bottom: 20px;
				}
			}
		}
	}
}
</style>
